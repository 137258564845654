import './jquery'
import 'jquery.cookie'
import './libs/jquery.textresizer'
import flatpickr from 'flatpickr'

$(function () {
  // 文字サイズ変更
  $('#fontSize a').textresizer({
    selector: '#fontSize a',
    // debugMode: true,
    sizes: ['1.25em', '1.125em', '1em']
  })
  $('#fontSize a').css('font-size', '12px')
  $('.idx li').css('font-size', '15px')

  // モバイル画面のメニュー
  $('#mobile_menu').on('click', function (event) {
    event.preventDefault()
    $('#mobile').css('display', 'block')
  })
  $('#mobile a').on('click', function () {
    $('#mobile').css('display', 'none')
  })
  $('#mobile a.close').on('click', function (event) {
    event.preventDefault()
  })

  // 管理画面のフィルタ
  $('#management #filter').on('change', function () {
    window.location = '/management/?filter=' + $('#management #filter').val()
  })

  // 画面保護
  function cover () {
    $('#top').append('<div id="cover"><div class="center-fixed"><div class="status"></div></div></div>')
  }
  function coverStatus (status) {
    document.querySelector('#cover .status').innerHTML = status
  }

  // インポートが終わるまでの画面保護
  $('#management #import').on('click', function (event) {
    event.preventDefault()
    cover()

    const formData = new FormData(document.getElementById('import-csv'))
    const xhr1 = new XMLHttpRequest()
    xhr1.open('POST', '/management/import_csv', true)
    xhr1.onload = function (event) {
      if (xhr1.readyState !== 4) { return }

      if (xhr1.status === 200) {
        const data = JSON.parse(xhr1.responseText)
        const alertMsg = data.alert || ''
        const noticeMsg = data.notice || ''
        window.location = '/management/imported?alert=' + alertMsg + '&notice=' + noticeMsg
        return
      }

      window.location = '/' + xhr1.status
    }
    xhr1.send(formData)

    const xhr = new XMLHttpRequest()
    setInterval(function () {
      xhr.open('GET', '/management/import_processed', false)
      xhr.send(null)
      if (xhr.status !== 200) { return }

      const data = JSON.parse(xhr.responseText)
      const count = parseFloat(data.count)
      const csvCount = parseFloat(data.csv_count)
      const percent = (count / csvCount * 100).toFixed()
      const status = count + ' / ' + csvCount + ' (' + percent + '%)'
      coverStatus(status)
    }, 1000)
  })

  // エクスポート後の画面更新
  $('#management #export').on('click', function () {
    const intervalId = setInterval(function () {
      $.get('/management/export_processed', function (data) {
        if (data.exportProcessed) {
          // export済みなのでリダイレクト
          clearInterval(intervalId)
          window.location = '/management'
        }
      })
    }, 1000)
  })

  // 支払履歴のダウンロード
  $('#management-payments #export').on('click', function () {
    cover()

    const intervalId = setInterval(function () {
      $.get('/management/payments/export_processed', function (data) {
        if (data.export_processed) {
          // export済みなのでリダイレクト
          clearInterval(intervalId)
          window.location = '/management/payments/exported'
        }
        const csvCount = parseFloat(data.csv_count)
        const count = csvCount - parseFloat(data.count)
        const percent = (count / csvCount * 100).toFixed()
        const status = count + ' / ' + csvCount + ' (' + percent + '%)'
        coverStatus(status)
      })
    }, 1000)
  })

  // 支払履歴のアップロード
  $('#management-payments #import-payment').on('click', function () {
    cover()

    setInterval(function () {
      $.get('/management/payments/import_processed', function (data) {
        const csvCount = parseFloat(data.csv_count)
        const count = parseFloat(data.count)
        const percent = (count / csvCount * 100).toFixed()
        const status = count + ' / ' + csvCount + ' (' + percent + '%)'
        coverStatus(status)
      })
    }, 1000)
  })

  // 支払履歴のアップロード（5年分）
  $('#management-payments #import5-payment').on('click', function () {
    cover()

    setInterval(function () {
      $.get('/management/payments/import_processed', function (data) {
        const csvCount = parseFloat(data.csv_count)
        const count = parseFloat(data.count)
        const percent = (count / csvCount * 100).toFixed()
        const status = count + ' / ' + csvCount + ' (' + percent + '%)'
        coverStatus(status)
      })
    }, 1000)
  })

  // Enterキーの無効化
  $('.no-enter').on('keydown', function (e) {
    if (e.which === 13) {
      return false
    }

    return true
  })

  // プレビュー対応
  const preview = document.getElementById('preview')
  if (preview !== null) {
    const submit = document.getElementById('submit')
    delete submit.dataset.disableWith
    submit.addEventListener('click', function () {
      this.form.target = '' // previewで変更されている場合があるので戻す
    })
    delete preview.dataset.disableWith
    preview.addEventListener('click', function () {
      const f = this.form
      f.target = 'preview'
      const w = window.open('about:blank', f.target)
      w.focus()
    })
  }

  /*
    $('.birthday4').keyup(change)
    $('.birthday2').keyup(change)

    function change (e) {
      console.log(e.key)
      if (e.key.length > 1) { return }

      this.value = this.value.replace(/[^\d]/, '')
      if (this.value.length >= this.maxLength) {
        const next = this.nextElementSibling
        if (next) { next.focus() }
      }
    }
  */

  window.onunload = function () {
    // console.log('unload')
  }
  // console.log('set unload')

  // 日時入力
  const flatpickrElements = document.querySelectorAll('.datetime')
  for (const element of flatpickrElements) {
    flatpickr(element, {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true
    })
  }
})
